import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.jsx';
import Navbar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';
import ProductsPage from './pages/Productos.jsx';
import ServicesPage from './pages/Servicios.jsx';



const AppRoutes = () => {
    return (
        <Router>
            <Navbar />
            <div style={{ minHeight: 'calc(100vh - 100px)' }}>
                {/* Ajusta el minHeight para dejar espacio al footer */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/pages/Productos" element={<ProductsPage />} />
                    <Route path="/pages/Home" element={<services-section />} />
                    <Route path="/pages/Servicios"element={<ServicesPage/>}/>
                </Routes>s
            </div>
            <Footer />
        </Router>
        
    );
};

export default AppRoutes;