import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from "./ave_esd1.png"


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
     <div className="navbar-brand">
  <a href="/" className="logo-link">
    {/* Texto en forma circular */}
    <svg className="logo-text-circle" viewBox="0 0 120 120">
      <defs>
        <path id="textPath" d="M 10,60 a 50,50 0 1,1 100,0 a 50,50 0 1,1 -100,0" />
      </defs>
      <text>
        <textPath xlinkHref="#textPath">Electro Seguridad Digital - </textPath>
      </text>
    </svg>

    {/* Logo como botón */}
    <img src={logo} alt="Logo Electroseguridad" className="navbar-logo" />
  </a>
</div>


      {/* Menú de enlaces */}
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <a href="/" className="nav-link">INICIO</a>
        <a href="../pages/Servicios" className="nav-link">SERVICIOS</a>
        <Link to="../pages/Productos" className="nav-link">PRODUCTOS</Link>
        <a href="#contact-section" className="nav-link">CONTACTO</a>
        
      </div>

      {/* Botón hamburguesa */}
      <div className={`navbar-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
