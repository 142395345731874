import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./home.css"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ExperienceStats from "../components/Experience_stats";
import Contacto from "../components/Contacto";

import img_s1 from "./servicio1_cam2.png";
import img_s2 from "./servicio2_biometric1.png"
import img_s3 from "./service3_antitheaf1.png"
import hik_logo from "./hikvision.png"
import zk_klogo from "./zkteco_logo.png"
import dahua_logo from "./dahua_logo.png"
import nexxt_logo from "./nexxt_logo.png"


const Home = () => {
  // Textos que cambian en la primera sección
  const texts = ["Visión: Ser una empresa de clase internacional, líder en el mercado, rentable, segura, confiable y competitiva", "Misión: Ayudar a nuestros clientes a alcanzar sus metas de negocio, proporcionándoles soluciones electrónicas de seguridad, comunicación y cableado estructural.", "Contactate con nosotros enseguida con el botón Quiero más información"];
  const [currentText, setCurrentText] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % texts.length);
    }, 8000); // Cambia el texto cada 8 segundos
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-container">
      {/* 🔹 Sección 1: Video + Texto */}
      
      <section className="hero-section">
        <div className="text-container">
          <h1>{texts[currentText]}</h1>
          <a
  href="https://wa.me/0986139787" 
  target="_blank"
  rel="noopener noreferrer"
>
  <button className="cta-button">Quiero más información</button>
</a>
        </div>
        <div className="video-container">
        <video src="/videos/video_intro.mp4" autoPlay loop muted></video>
        </div>
      </section>
 {/* 🔹 Sección 3: Swiper de Marcas */}
 <section className="brands-section">
        <Swiper spaceBetween={20} slidesPerView={4} autoplay={{ delay: 4000 }}>
          <SwiperSlide>
            <button className="brand-button" onClick={() => alert("Detalles de la Marca 1")}>
              <img src={nexxt_logo} alt="Marca 1" />
            </button>
          </SwiperSlide>
         
          <SwiperSlide>
            <button className="brand-button" onClick={() => alert("Detalles de la Marca 2")}>
              <img src={hik_logo} alt="Marca 2" />
            </button>
          </SwiperSlide>
          <SwiperSlide>
            <button className="brand-button" onClick={() => alert("Detalles de la Marca 3")}>
              <img src={zk_klogo} alt="Marca 3" />
            </button>
          </SwiperSlide>
          <SwiperSlide>
            <button className="brand-button" onClick={() => alert("Detalles de la Marca 4")}>
              <img src={dahua_logo} alt="Marca 4" />
            </button>
          </SwiperSlide>
        </Swiper>
      </section>
      

      {/* 🔹 Sección 2: Servicios */}
      <section className="services-section">
        <div className="service-box">
          <img src={img_s1} alt="Servicio 1" />
          <h3>Sistema de camaras de vigilancia </h3>
          <p>Instalación de camaras de vigilancia conm las mejores marcas del mercado.</p>
          <button onClick={() => window.location.href = "/pages/Servicios"}>Ver más</button>
        </div>
        <div className="service-box">
          <img src={img_s2} alt="Servicio 2" />
          <h3>Sistema de registro con reloj Biometrico</h3>
          <p>Instalamos sistemas de registro de empleados o asistencia en general para emrpesas con las mejores marcas del mercado </p>
          <button onClick={() => window.location.href = "/pages/Servicios"}>Ver más</button>
        </div>
        <div className="service-box">
          <img src={img_s3} alt="Servicio 3" />
          <h3>Sistema anti-intrusos</h3>
          <p>Instalamos sistemas con sensores anti intrusos para su hogar o local comercial, con las mejores marcas del mercado</p>
          <button onClick={() => window.location.href = "/pages/Servicios"}>Ver más</button>
        </div>
      </section>
      <div>
      <ExperienceStats/>

       <Contacto/> 
      </div>
     
   
    
    </div>
    
  );
};

export default Home;
