// Contacto.jsx
import React from "react";
import "./contacto.css";

const Contacto = () => {
  return (
    <section className="contact-section" id="contact-section">
  <div className="contact-card">
    <div className="contact-info">
      <p><span>📍 Dirección:</span> AMBATO SECTOR EL ARBOLITO AV. LOS CHASQUIS Y PICHINCHA ALTA</p>
      <p><span>📧 Correo:</span> gerencia@electroseguridaddigital.com</p>
      <p><span>📱 Celular:</span> 0998767681 / 0986139787</p>
      <p><span>🕓 Horario:</span> Lun a Vie - 8:00 a 17:30 - No cerramos al medio día.</p>
    </div>
    <div className="contact-action">
      <a
        href="https://wa.me/0998767681?text=Hola!%20Quiero%20más%20información%20sobre%20los%20servicios%20de%20seguridad"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="contact-button"> WhatsApp</button>
      </a>
    </div>
  </div>
</section>
  );
};

export default Contacto;
