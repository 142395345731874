import React, { useState } from "react";
import "./products.css";

/*IMG PRODUCTOS ALARMAS*/
import alrm_1 from "./img_p/alr_1.png";
import alrm_2 from "./img_p/alr_2.png";
import alrm_3 from "./img_p/alr_3.png";
import alrm_4 from "./img_p/alr_4.png";
import alrm_5 from "./img_p/alr_5.png";
import alrm_6 from "./img_p/alr_6.png";
import alrm_7 from "./img_p/alr_7.png";
import alrm_8 from "./img_p/alr_8.png";
import alrm_9 from "./img_p/alr_9.png";
import alrm_10 from "./img_p/alr_10.png";
import alrm_11 from "./img_p/alr_11.png";
import alrm_12 from "./img_p/alr_12.png";
import alrm_13 from "./img_p/alr_13.png";
import alrm_14 from "./img_p/alr_14.png";
import alrm_15 from "./img_p/alr_15.png";
import alrm_16 from "./img_p/alr_16.png";
import alrm_17 from "./img_p/alr_17.png";
/*IMG PRODUCTOS CCTV */

import cctv_1 from "./img_p/cctv_1.png";
import cctv_2 from "./img_p/cctv_2.png";
import cctv_3 from "./img_p/cctv_3.png";
import cctv_4 from "./img_p/cctv_4.png";
import cctv_5 from "./img_p/cctv_5.png";
import cctv_6 from "./img_p/cctv_6.png";
import cctv_7 from "./img_p/cctv_7.png";
import cctv_8 from "./img_p/cctv_8.png";
import cctv_9 from "./img_p/cctv_9.png";
import cctv_10 from "./img_p/cctv_10.png";
import cctv_11 from "./img_p/cctv_11.png";
import cctv_12 from "./img_p/cctv_12.png";
import cctv_13 from "./img_p/cctv_13.png";
import cctv_14 from "./img_p/cctv_14.png";
import cctv_15 from "./img_p/cctv_15.png";
import cctv_16 from "./img_p/cctv_16.png";

/*IMG CONTRA INCENDIO*/
import fire_1 from "./img_p/fire_1.png";
import fire_2 from "./img_p/fire_2.png";
import fire_3 from "./img_p/fire_3.png";
import fire_4 from "./img_p/fire_4.png";
import fire_5 from "./img_p/fire_5.png";
import fire_6 from "./img_p/fire_6.png";

/*IMAGENES MOTOR*/ 
import mtr_1 from "./img_p/mrt_1.png";
import mtr_2 from "./img_p/mtr_2.png";
import mtr_3 from "./img_p/mrt_3.png";
import mtr_4 from "./img_p/mtr_4.png";
import mtr_5 from "./img_p/mtr_5.png";
import mtr_6 from "./img_p/mtr_6.png";
/*IMAGENES VP*/

import vp_1 from "./img_p/vp_1.png"
import vp_2 from "./img_p/vp_2.png"
import vp_3 from "./img_p/vp_3.png"

const ProductsPage = () => {
  // 🔹 Lista de productos con marca y servicio
  const products = [
    // 📌 SECCION ALARMAS 
    { id: 1, name: "NEO TECLADO ALFANUMERICO", details: "asdfasdfasdfasdf", service: "Alarma", image: alrm_1, whatsapp: "0998767681" },
    { id: 5, name: "Tarjetas Neo 'Panel 32 zonas' ", service: "Alarma", image: alrm_2, whatsapp: "0998767681" },
    { id: 6, name: "NEO comunicador ethernet",  service: "Alarma", image: alrm_3, whatsapp: "0998767681" },
    { id: 7, name: "Neo Modulo Expansor De 8 Zonas",  service: "Alarma", image: alrm_4, whatsapp: "0998767681" },
    { id: 2, name: " Detector de movimiento para interior", service: "Alarma", image: alrm_5, whatsapp: "0998767681" },
    { id: 8, name: "Pulsador De Panico Con Enclavamiento - Llave Marca Enforce",  service: "Alarma", image: alrm_6, whatsapp: "0998767681" },
    { id: 9, name: "Contacto fuerza lanfor ",  service: "Alarma", image: alrm_7, whatsapp: "0998767681" },
    { id: 10, name: "Sirena 30 Watts", service: "Alarma", image:alrm_8, whatsapp: "0998767681" },    
    { id: 3, name: "Gabinete metálico 60x40x23mm doble fondo",  service: "Alarma", image: alrm_9, whatsapp: "0998767681" },
    { id: 11, name: "Fuente de Alimentación ", service: "Alarma", image: alrm_10, whatsapp: "0998767681" },
    { id: 12, name: "Bateria 7amp-4amp", service: "Alarma", image: alrm_11, whatsapp: "0998767681" },
    { id: 13, name: "Transformador 16.5V", service: "Alarma", image: alrm_13, whatsapp: "0998767681" },
    { id: 14, name: "Pulsador de pánico inhalámbrico",  service: "Alarma", image:alrm_14, whatsapp: "0998767681" },
    { id: 15, name: "CKIT ALARMA HIK-VISION",service: "Alarma", image: alrm_15, whatsapp: "0998767681" },
    { id: 16, name: "KIT ALARMA INTELBRAS",  service: "Alarma", image: alrm_16, whatsapp: "0998767681" },
    { id: 17, name: "Cámara Térmica Dahua", service: "Alarma", image: alrm_17, whatsapp: "0998767681" },
    { id: 18, name: "Sensor de Humo Hikvision", service: "Alarma", image: alrm_1, whatsapp: "0998767681" },
    
    /*IMAGES FOR CCTV PRODUCTS*/
    { id: 19, name: "NVR 16CH ip",service: "CCTV", image: cctv_1, whatsapp: "0998767681" },
    { id: 20, name: "DISCO DURO WESTERN DIGITAL PURPLE 6TB",  service: "CCTV", image: cctv_2, whatsapp: "0998767681" },
    { id: 21, name: "CAMARA DOMO IP ",  service: "CCTV", image: cctv_3, whatsapp: "0998767681" },
    { id: 22, name: "CAMARA IP TUBO", service: "CCTV",  image: cctv_4, whatsapp: "0998767681" },
    { id: 23, name: "SWITCH POE 24 PUERTOS ADMINISTRADO",  service: "CCTV", image:cctv_5, whatsapp: "0998767681" },
    { id: 24, name: "CAMARA CON AUDIO IP67",  service: "CCTV", image: cctv_6, whatsapp: "0998767681" },
    { id: 15, name: "DVR/XVR ANALOGICA",  service: "CCTV", image: cctv_7, whatsapp: "0998767681" },
    { id: 26, name: "CCAMARA ANALOGA DOMO", service: "CCTV", image: cctv_8, whatsapp: "0998767681" },
    { id: 27, name: "CAMARA ANALOGA TUBO", service: "CCTV", image: cctv_9, whatsapp: "0998767681" },
    { id: 28, name: "BALUM HD", service: "CCTV", image: cctv_10, whatsapp: "0998767681" },
    { id: 29, name: "FUENTE PARA CAMARA", service: "CCTV", image: cctv_11, whatsapp: "0998767681" },
    { id: 30, name: "CONECTOR MACHO HEMBRA", service: "CCTV", image: cctv_12, whatsapp: "0998767681" },
    { id: 31, name: "CAMARA WIFI 1080P", service: "CCTV", image: cctv_13, whatsapp: "0998767681" },
    { id: 32, name: "MICRO SD", service: "CCTV", image: cctv_14, whatsapp: "0998767681" },
    { id: 33, name: "CAMRA HERMETICA", service: "CCTV", image: cctv_15, whatsapp: "0998767681" },
    { id: 34, name: "CABLE UTP CAT5 Y 6", service: "CCTV", image:cctv_16, whatsapp: "0998767681" },
   
   /* IMAGENES PICS CONTRA INCENDIO*/
    { id: 35, name: "CENTRAL DE INCENDIO DIRECCIONABLE / NO DIRECCIONABLE", service: "Contra incendio", image: fire_1, whatsapp: "0998767681" },
    { id: 36, name: "Detector de Humo 4 hilo fotoelectricos",  service: "Contra incendio", image: fire_2, whatsapp: "0998767681" },
    { id: 37, name: "Estación Manual de Incendio ",  service: "Contra incendio", image: fire_3, whatsapp: "0998767681" },
    { id: 38, name: "Sirena con luz Estoboscopica",  service: "Contra incendio", image: fire_4, whatsapp: "0998767681" },
    { id: 39, name: "Luz de Salida Led",  service: "Contra incendio", image: fire_5, whatsapp: "0998767681" },
    { id: 40, name: "Luces de emergenciasz",  service: "Contra incendio", image: fire_6, whatsapp: "0998767681" }, 
 
/*IMAGENES MOTOR*/
   { id: 41, name: "Kit motor de Brazos", service: "Motor", image: mtr_1, whatsapp: "0998767681" },
   { id: 42, name: "Kit motor corredizo",  service: "Motor", image: mtr_2, whatsapp: "0998767681" },
   { id: 43, name: "Kit motor enrollable ",  service: "Motor", image: mtr_3, whatsapp: "0998767681" },
   { id: 44, name: "Kit motor de cadena",  service: "Motor", image: mtr_4, whatsapp: "0998767681" },
   { id: 45, name: "Smart Garage",  service: "Motor", image: mtr_5, whatsapp: "0998767681" },
   { id: 46, name: "Piñones de Motor",  service: "Motor", image: mtr_6, whatsapp: "0998767681" }, 


/*Video Portero*/
   { id: 47, name: "Kit Video Portero Placa Un Punto Con Motor", service: "Video Portero", image: vp_1, whatsapp: "0998767681" },
   { id: 48, name: "Cerradura Electrica",  service: "Video Portero", image: vp_2, whatsapp: "0998767681" },
   { id: 49, name: "Cerradura de Piso ",  service: "Video Portero", image: vp_3, whatsapp: "0998767681" },






  ];
  

  // 🔥 Estados para los filtros
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedService, setSelectedService] = useState("");

  // 🔎 Función de filtrado
  const filteredProducts = products.filter((product) => {
    const matchBrand = selectedBrand ? product.brand === selectedBrand : true;
    const matchService = selectedService ? product.service === selectedService : true;
    return matchBrand && matchService;
  });

  // 🔥 Estado del Modal
  const [selectedProduct, setSelectedProduct] = useState(null);

  return (
    <div className="products-container">
      <h1>Nuestros Productos</h1>

      {/* 🔹 Filtros */}
      <div className="filters">
      

        <select value={selectedService} onChange={(e) => setSelectedService(e.target.value)}>
          <option value="">Todos los Servicios</option>
          <option value="Alarma">Alarma</option>
          <option value="CCTV">CCTV</option>
          <option value="Contra incendio">Contra incendio</option>
          <option value="Motor">Motor</option>
          <option value="Video Portero">Video Portero</option>
        </select>

        {/* Botón para limpiar los filtros */}
        <button className="clear-filters" onClick={() => { setSelectedBrand(""); setSelectedService(""); }}>
          Limpiar Filtro
        </button>
      </div>

      {/* 🔹 Grid de Productos Filtrados */}
      <div className="products-grid">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.name} />
              <h3>{product.name}</h3>
              <p> {product.service}</p>

              {/* Botón para WhatsApp */}
              <a
                href={`https://wa.me/${product.whatsapp}?text=Hola, estoy interesado en el producto: ${product.name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="whatsapp-button">Solicitar Precio</button>
              </a>

              {/* Botón para Ver Detalles */}
              <button onClick={() => setSelectedProduct(product)}>Ver Detalles</button>
            </div>
          ))
        ) : (
          <p className="no-results">No se encontraron productos.</p>
        )}
      </div>

      {/* 🔹 Modal de Detalles */}
      {selectedProduct && (
        <div className="modal-overlay active">
          <div className="modal-content">
            <button className="close-button" onClick={() => setSelectedProduct(null)}>×</button>
            <h2>{selectedProduct.name}</h2>
            <img src={selectedProduct.image} alt={selectedProduct.name} className="main-image" />
            <p> {selectedProduct.service}</p>

            {/* Botón de WhatsApp dentro del modal */}
            <a
              href={`https://wa.me/${selectedProduct.whatsapp}?text=Hola Electro Seguridad Digital, me interesa este producto! ¿Me ayudas con los precios y modos de pago? ${selectedProduct.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="whatsapp-button">Solicitar Precio</button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductsPage;
