import React from "react";
import "./servicios.css";




const services = [
  {
    id: 1,
    name: "Vigilancia en Video",
    description:
      "Sistema de videovigilancia de alta precisión con grabación en la nube y acceso en tiempo real. Ideal para supervisión continua en entornos empresariales y residenciales.",
    features: [
      "Monitoreo 24/7",
      "Almacenamiento en la nube",
      "Detección de movimiento inteligente",
      "Acceso desde cualquier dispositivo",
      "Historial de grabaciones consultable"
    ],
    image: "/images/service-esd1.png",
    whatsapp: "0998767681",
  },
  {
    id: 2,
    name: "Seguridad Perimetral",
    description:
      "Defensa integral del perímetro mediante sensores inteligentes, cercos electrificados y sistemas de alerta inmediata. Optimizado para prevenir accesos no autorizados.",
    features: [
      "Cobertura completa del perímetro",
      "Respuesta inmediata ante intrusos",
      "Sensores de última generación",
      "Integración con cámaras y alarmas",
      "Mantenimiento remoto y diagnóstico"
    ],
    image: "/images/service-esd2.png",
    whatsapp: "0998767681",
  },
  {
    id: 3,
    name: "Control de Acceso",
    description:
      "Tecnología de acceso inteligente que incluye huella digital, tarjetas RFID y reconocimiento facial. Ideal para restringir y monitorear ingresos a zonas sensibles.",
    features: [
      "Seguridad basada en identidad biométrica",
      "Registro de entradas y salidas",
      "Compatible con múltiples métodos de autenticación",
      "Gestión desde aplicación móvil",
      "Bloqueo automático ante accesos no autorizados"
    ],
    image: "/images/service-esd3.png",
    whatsapp: "0998767681",
  },
  {
    id: 4,
    name: "Sistema Contra Incendios",
    description:
      "Implementación de sistemas automáticos de detección y supresión de incendios. Incluye detectores de humo, calor y rociadores para actuar ante cualquier foco.",
    features: [
      "Alerta temprana de fuego y humo",
      "Activación automática de rociadores",
      "Conectividad con central de monitoreo",
      "Prevención de pérdidas materiales",
      "Cumplimiento de normativas NFPA"
    ],
    image: "/images/service-esd4.png",
    whatsapp: "0998767681",
  }
];

const ServicesPage = () => {
  return (
    <div className="services-container">
      {services.map((service) => (
        <div key={service.id} className="service-card">
          <img src={service.image} alt={service.name} className="service-image" />
          <div className="service-details">
            <h2>{service.name}</h2>
            <p>{service.description}</p>
            <ul>
              {service.features.map((feature, index) => (
                <li key={index}>✅ {feature}</li>
              ))}
            </ul>
            <a
              href={`https://wa.me/${service.whatsapp}?text=Hola, estoy interesado en el servicio de ${service.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="whatsapp-button">Solicitar Información</button>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesPage;
