import React, { useEffect, useState, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import "./experience_stats.css";

const ExperienceStats = () => {
  const stats = [
    { label: "Clientes Satisfechos", value: 1200 },
    { label: "Proyectos Realizados", value: 350 },
    { label: "Horas de Soporte", value: 5000 },
    { label: "Años de Experiencia", value: 20 }, // Nueva barra
  ];

  const ref = useRef(null);
  const isInView = useInView(ref, { threshold: 0.5 });
  const controls = useAnimation();
  const [counts, setCounts] = useState(stats.map(() => 0));

  useEffect(() => {
    if (isInView) {
      const speed = 30;
      const interval = setInterval(() => {
        setCounts((prevCounts) =>
          prevCounts.map((count, i) =>
            count < stats[i].value
              ? count + Math.ceil(stats[i].value / speed)
              : stats[i].value
          )
        );
      }, 50);
      return () => clearInterval(interval);
    }
  }, [isInView, stats]);

  return (
    <motion.div 
      ref={ref} 
      className="experience-container"
      initial={{ opacity: 0, y: 50 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8, ease: "easeOut" }}
    >
      {stats.map((stat, i) => (
        <motion.div
          key={i}
          className={`stat-box ${stat.label === "Años de Experiencia" ? "years-box" : ""}`}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={isInView ? { scale: 1, opacity: 1 } : {}}
          transition={{ duration: 0.6, delay: i * 0.2 }}
        >
          <motion.div
            className={`bar ${stat.label === "Años de Experiencia" ? "years-bar" : ""}`}
            style={{ height: `${(counts[i] / stats[i].value) * 100}%` }}
            transition={{ duration: 1, ease: "easeOut" }}
          ></motion.div>
          <motion.h2>{counts[i]}+</motion.h2>
          <p>{stat.label}</p>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default ExperienceStats;
