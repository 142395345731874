import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaCogs, FaBox, FaEnvelope } from 'react-icons/fa';
import './footer.css';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="footer-content">
                <p className="footer-phrase">
                    "Caminos seguros para la tecnología"
                </p>
                
                <nav className="footer-nav">
                    <button className="footer-button" onClick={() => navigate('/')}>
                        <FaHome className="icon" /> Inicio
                    </button>
                    <button className="footer-button" onClick={() => navigate('../pages/Servicios')}>
                        <FaCogs className="icon" /> Servicios
                    </button>
                    <button className="footer-button" onClick={() => navigate('../pages/Productos')}>
                        <FaBox className="icon" /> Productos
                    </button>
                    <button className="footer-button" onClick={() => navigate('/')}>
                        <FaEnvelope className="icon" /> Contacto
                    </button>
                </nav>

                <p className="footer-copy">&copy; {new Date().getFullYear()} WEB CREATED BY sec-hat.com</p>
            </div>
        </footer>
    );
};

export default Footer;
